import { NgClass } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { PRE_ORDER_ERRORS_MAP } from 'src/app/presentation/shared/constants/builk-pre-order-v3-error-codes';
import { SUBMIT_PREORDER_REQUEST } from 'src/app/presentation/shared/constants/mixpanel';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { BulkPreOrderRequestModel } from '../../../../core/domain/bulk-pre-order/bulk-pre-order-request.model';
import { CreateBulkPreOrderRequestUseCase } from '../../../../core/usecases/bulk-preorder/create-bulk-pre-order-request.usecase';
import { PreOrderSuccessfulDialogComponent } from '../pre-order-successful-dialog/pre-order-successful-dialog.component';

@Component({
  selector: 'app-amount-lock-dialog',
  templateUrl: './amount-lock-dialog.component.html',
  styleUrls: ['./amount-lock-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class AmountLockDialogComponent implements OnInit {
  public amount: number;

  public quantity: number;

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private dialogRef: MatDialogRef<AmountLockDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      amount: number;
      quantity: number;
      productId: string;
      depositRatio?: number;
      comment?: string;
    },
    private createBulkPreOrderRequest: CreateBulkPreOrderRequestUseCase,
    private _toastrService: ToastrService,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.amount = this.data.amount;
    this.quantity = this.data.quantity;
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onProceed(): void {
    const requestModel: BulkPreOrderRequestModel = {
      prodId: this.data.productId,
      requestedQuantity: this.quantity,
      downPaymentPercentageSuggestedByMerchant: this.data.depositRatio,
      notesAddedByMerchant: this.data.comment,
    };
    this.createBulkPreOrderRequest.execute(requestModel).subscribe({
      next: () => {
        this._logMixpanelEventUseCase.execute({
          eventName: SUBMIT_PREORDER_REQUEST,
          payload: requestModel,
        });
        this.dialogRef.afterClosed().subscribe({
          next: () => {
            this.dialog.open(PreOrderSuccessfulDialogComponent, {
              width: '484px',
              data: {},
              direction: this._siteTranslateService.currentDir,
            });
          },
        });
        this.dialogRef.close();
      },
      error: (err) => {
        const errorCode = err?.error?.errorCode;
        const errorMappedMessage =
          PRE_ORDER_ERRORS_MAP.get(errorCode) || 'PRE_ORDER_DIALOG.ERRORS.GENERAL_ERROR';
        this._toastrService.error(this._translateService.instant(errorMappedMessage));
      },
    });
  }

  onCancel(): void {
    this.onDismiss();
  }
}
