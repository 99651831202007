<div class="pre-order-success-dialog-wrapper">
  <img class="pre-order-success-icon" src="/assets/img/bulk-pre-order-success.svg" />
  <h2>{{ 'PRE_ORDER_REQUEST_SUCCESSFUL.TITLE' | translate }}</h2>
  <p class="pre-order-success-content">
    {{ 'PRE_ORDER_REQUEST_SUCCESSFUL.TEXT' | translate }}
  </p>
  <button
    class="btn btn-proceed"
    [ngClass]="{ 'btn-register-mob': isMobile }"
    (click)="navigateToPreOrderRequestsPage()"
  >
    {{ 'PRE_ORDER_REQUEST_SUCCESSFUL.BUTTON' | translate }}
  </button>
</div>
