<div class="amount-lock-dialog-wrapper">
  <div class="close" (click)="onDismiss()"><img src="/assets/img/close.svg" /></div>
  <h2>{{ 'PRE_ORDER_CONFIRM_DIALOG.TITLE' | translate }}</h2>
  <p class="dialog-content">
    {{ 'PRE_ORDER_CONFIRM_DIALOG.TEXT' | translate : { quantity, amount } }}
  </p>
  <div class="button-row">
    <button class="btn btn-cancel" (click)="onCancel()">
      {{ 'PRE_ORDER_CONFIRM_DIALOG.CANCEL' | translate }}
    </button>
    <button class="btn btn-proceed" type="submit" (click)="onProceed()">
      {{ 'PRE_ORDER_CONFIRM_DIALOG.CONFIRM' | translate }}
    </button>
  </div>
</div>
