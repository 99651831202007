import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { PreOrderRulesDialogComponent } from 'src/app/presentation/pre-order-requests/pre-order-rules-dialog/pre-order-rules-dialog.component';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { AmountLockDialogComponent } from '../amount-lock-dialog/amount-lock-dialog.component';

@Component({
  selector: 'app-bulk-pre-order-dialog',
  templateUrl: './bulk-pre-order-dialog.component.html',
  styleUrls: ['./bulk-pre-order-dialog.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgClass, TranslateModule],
})
export class BulkPreOrderDialogComponent implements OnInit {
  public productPrice: number;

  private _depositRatio = 0.3;

  public maxQuantity: number;

  public preOrderQuantity: number;

  public reservedAmount = 0;

  public minimumRequestedQuantity = 100;

  public quantityForm: UntypedFormGroup;

  private _isConfirmationDialogOpen = false;

  public preorderV2FeatureEnabled = false;

  constructor(
    private dialogRef: MatDialogRef<BulkPreOrderDialogComponent>,
    private dialog: MatDialog,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      walletAmount: number;
      sellingPrice: number;
      merchantMargin: number;
      productId: string;
    },
    private _siteTranslateService: SiteTranslateService,
  ) {}

  ngOnInit(): void {
    this.productPrice = this.data.sellingPrice - this.data.merchantMargin;
    this.maxQuantity = Math.floor(this.data.walletAmount / (this.productPrice * 0.3));
    this.quantityForm = new UntypedFormGroup({
      quantity: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.minimumRequestedQuantity),
        Validators.max(this.maxQuantity),
      ]),
    });

    this.dialogRef.afterClosed().subscribe({
      next: (data) => {
        if (!this._isConfirmationDialogOpen && data && data.success) {
          this._isConfirmationDialogOpen = true;
          this.dialog.open(AmountLockDialogComponent, {
            width: '484px',
            data: {
              amount: this.reservedAmount,
              quantity: this.preOrderQuantity,
              productId: this.data.productId,
            },
            direction: this._siteTranslateService.currentDir,
          });
        }
      },
    });
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_V2).subscribe({
      next: (flag) => {
        this.preorderV2FeatureEnabled = flag;
      },
    });
  }

  onUpdateQuantity(): void {
    if (this.preOrderQuantity <= this.maxQuantity) {
      this.reservedAmount = Math.floor(
        this.preOrderQuantity * this.productPrice * this._depositRatio,
      );
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onProceed(): void {
    this.dialogRef.close({ success: true });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  openRulesDialog(event: any): void {
    event.preventDefault();
    this._dialog.open(PreOrderRulesDialogComponent, {
      width: '905px',
      height: '700px',
      direction: this._siteTranslateService.currentDir,
    });
  }
}
