<form class="preorder-form" [formGroup]="quantityForm">
  <div class="title-row">
    <h2 class="dialog-title">{{ 'PRE_ORDER_DIALOG.TITLE' | translate }}</h2>
    <div class="close" (click)="onDismiss()"><img src="/assets/img/close.svg" /></div>
  </div>
  <div class="form-group">
    <div class="max-quantity-container">
      <p class="max-quantity">
        {{ 'PRE_ORDER_DIALOG.RESERVE_QUANTITY' | translate }}
      </p>
      <p class="max-quantity__warning">
        {{ 'PRE_ORDER_DIALOG.WARNING' | translate }}
      </p>
    </div>
    <hr />
    <div class="input-row">
      <input
        class="form-control quantity"
        type="number"
        formControlName="quantity"
        placeholder="{{ 'PRE_ORDER_DIALOG.QUANTITY_TO_RESERVE' | translate }}"
        [(ngModel)]="preOrderQuantity"
        (ngModelChange)="onUpdateQuantity()"
      />
      <div class="preorder-price-container">
        <p class="preorder-price-label">
          {{ 'PRE_ORDER_DIALOG.EXPECTED_DOWNPAYMENT' | translate }}
        </p>
        <p class="preorder-price">{{ reservedAmount }}</p>
      </div>
    </div>
    <div class="amount-container">
      <p class="amount-text">{{ 'PRE_ORDER_DIALOG.MINIMUM_QUANTITY_TO_RESERVE' | translate }}:</p>
      <p class="amount-number">&nbsp;{{ minimumRequestedQuantity }}</p>
    </div>
    <div class="amount-container">
      <p class="amount-text">
        {{ 'PRE_ORDER_DIALOG.MAXIMUM_QUANTITY_ACCORDING_TO_WALLET_BALANCE' | translate }}:
      </p>
      <p class="amount-number">&nbsp;{{ maxQuantity <= 0 ? 0 : maxQuantity }}</p>
    </div>
    <p *ngIf="preorderV2FeatureEnabled" class="info-message">
      {{ 'PRE_ORDER_DIALOG.TERMS_AND_CONDITIONS' | translate }}
      <a (click)="openRulesDialog($event)" href="#"> {{ 'PRE_ORDER_DIALOG.HERE' | translate }} </a>.
    </p>
  </div>

  <div class="action-buttons">
    <button
      class="btn btn-proceed"
      [disabled]="quantityForm.invalid"
      type="submit"
      (click)="onProceed()"
    >
      {{ 'PRE_ORDER_DIALOG.CONFIRM' | translate }}
    </button>
    <button class="btn btn-cancel" (click)="onCancel()">
      {{ 'PRE_ORDER_DIALOG.CANCEL' | translate }}
    </button>
  </div>
</form>
