import { NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ResponsiveService } from '../../../shared/services/responsive.service';

@Component({
  selector: 'app-pre-order-successful-dialog',
  templateUrl: './pre-order-successful-dialog.component.html',
  styleUrls: ['./pre-order-successful-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class PreOrderSuccessfulDialogComponent implements OnInit {
  public isMobile: boolean;

  constructor(
    private responsiveService: ResponsiveService,
    private _router: Router,
    private dialogRef: MatDialogRef<PreOrderSuccessfulDialogComponent>,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  ngOnInit(): void {
    this.getIsMobile();
  }

  navigateToPreOrderRequestsPage(): void {
    this.dialogRef.close();
    this._router.navigate([this._appURLs.PRE_ORDER_REQUESTS_URL]);
  }

  private getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }
}
